<template>
  <Stack v-if="branch" tag="div" gap="none" justify="center">
    <NuxtLink
      :to="`/branches/${branch[0]?.parent && branch[0]?.parent.slug}`"
      class="basis-1/2 sm:basis-auto p-xs transition duration-200 font-semibold capitalize flex gap-xs items-center justify-center hover:text-mkm-blue-light border-b-lg md:px-lg"
      :class="[
        !branch[0]?.parent
          ? 'pointer-events-none border-mkm-blue-light text-mkm-blue-light'
          : 'border-grey-light text-charcoal-default',
      ]"
    >
      <Icon name="drill" :size="16" />
      Build
    </NuxtLink>

    <div v-if="branch[0]?.children && branch[0]?.children.length > 0">
      <NuxtLink
        v-for="(child, index) in branch[0]?.children"
        :key="index"
        :to="`/branches/${child.slug}`"
        class="ui-basis-1/2 sm:ui-basis-auto ui-p-xs ui-transition ui-duration-200 ui-font-semibold ui-capitalize ui-flex ui-gap-xs ui-items-center ui-justify-center hover:ui-text-mkm-blue-light ui-border-b-lg md:ui-px-lg border-grey-light text-charcoal-default"
      >
        <Icon name="home" :size="16" />

        Home
      </NuxtLink>
    </div>

    <NuxtLink
      v-else
      :to="`/branches/${branch[0]?.slug}`"
      class="ui-basis-1/2 sm:ui-basis-auto ui-p-xs ui-transition ui-duration-200 ui-font-semibold ui-capitalize ui-flex ui-gap-xs ui-items-center ui-justify-center hover:ui-text-mkm-blue-light ui-border-b-lg md:ui-px-lg"
      :class="[
        branch[0]?.slug === route.params.branch
          ? 'border-mkm-blue-light text-mkm-blue-light'
          : 'border-grey-light text-charcoal-default',
      ]"
    >
      <Icon name="home" :size="16" />
      Home
    </NuxtLink>
  </Stack>
</template>

<script setup lang="ts">
import type { BranchTabsProps } from "./branchDetailsTypes";
const route = useRoute();

const props = defineProps<BranchTabsProps>();

const { branch } = toRefs(props);
</script>
